const MEDIA_TYPE: any = {
    JSON: 'application/json',
    CSV: "text/csv",
    KML: "application/vnd.google-earth.kml+xml"
}

const SHAPE_TYPE = {
    NOT_APPLICABLE: '',
    POINT: 'point',
    LINE: 'line',
    POLYGON: 'polygon',
    WKT: 'wkt'
}

// Shape Attributes defined how to interpret a field in geospatial terms
const SHAPE_ATTRIBUTE = {
    NOT_APPLICABLE: '',
    LATITUDE_START: 'latitude_start',	// for point or line
    LATITUDE_END: 'latitude_end',		// for line
    LONGITUDE_START: 'longitude_start', // for point or line
    LONGITUDE_END: 'longitude_end',		// for line
    WKT: 'wkt'							// for point, line or polygon
}

const ALTITUDE_MODE = {
    ABSOLUTE: "absolute",
    RELATIVE_TO_GROUND: "relativeToGround",
    CLAMP_TO_GROUND: "clampToGround"
}

const RW_IOT = {
    ACKNOWLEDGE_TOPIC_SUFFIX: 'www.roames.net',
    ACTION_TOPIC_SUFFIX: 'roamesworld-action'
}

enum RWActionTypes {
    FlyTo = 'FlyTo',
    FlyToBounds = 'FlyToBounds',
    DrawPlacemark = 'DrawPlacemark',
    DrawPolygon = 'DrawPolygon',
    DrawLine = 'DrawLine',
    Presence = 'Presence'
}

const DEFAULT_FAVORITE_ID = 'default_19042bbb-8ded-4574-94c3-ed8839c940a8';

const MAX_ROWS_EXPORT = {
    CSV: 100000,
    KML: 100000
}

const TOOLTIP_TIMING = {
    Enter: 300,
    Leave: 200
}

export interface ViewType {
    icon: string;
    text: string;
    type: string; 
    route: string;
}

const ASSET_TYPES: ViewType[] = [{
    icon: 'pole',
    text: 'Asset Model',
    type: '(Asset Model|Network Maintenence|Network Maintenance|Other)',
    route: 'asset-model'
},
{
    icon: 'park_black_24dp',
    text: 'Vegetation Model',
    type: 'Vegetation',
    route: 'vegetation-model'
}];

const MAX_NUMBER_OF_FILTER_ENTRIES = 200;

export {
    MEDIA_TYPE,
    SHAPE_TYPE,
    SHAPE_ATTRIBUTE,
    ALTITUDE_MODE,
    RW_IOT,
    RWActionTypes,
    DEFAULT_FAVORITE_ID,
    MAX_ROWS_EXPORT,
    TOOLTIP_TIMING,
    ASSET_TYPES,
    MAX_NUMBER_OF_FILTER_ENTRIES
};
